th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-inline: 16px !important;
  height: 51px;
}

td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-inline: 16px !important;
  height: 46px;
}

.otp-input {
  width: 100%;
  min-width: calc(100% / 6 - 16.6667px);
  height: 56px;
  border-radius: 12px;
  outline: 1px solid #7d8d96;
  border: none;
}

.otp-input-disabled {
  width: 100%;
  min-width: calc(100% / 6 - 16.6667px);
  height: 56px;
  border-radius: 12px;
  outline: 1px solid #7d8d96;
  border: none;
}

.otp-input:hover {
  outline: 1px solid #000000;
}

/* hide number selector */
.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.otp-input-disabled::-webkit-outer-spin-button,
.otp-input-disabled::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-disabled[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

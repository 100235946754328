.popover-paper-date-range-picker {
  border-radius: 8px;
  box-shadow: 0px 0px 24px 0px rgba(17, 67, 97, 0.08) !important;
  min-height: 334px !important;
}

.popover-paper-date-range-picker .Mui-selected {
  background-color: #1b8ffd !important;
}

.popover-paper-date-range-picker .Mui-selected:hover {
  background-color: #1b8ffd !important;
}

.popover-paper {
  border-radius: 8px !important;
  box-shadow: 0px 0px 24px 0px rgba(17, 67, 97, 0.08) !important;
  max-height: 197px !important;
  overflow-y: scroll;
}

.select-item:hover {
  background-color: #f9fafa !important;
}

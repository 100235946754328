@import url("https://fonts.cdnfonts.com/css/satoshi");

*:focus-visible {
  outline: 2.5px solid #93b3f2 !important;
}

* {
  box-sizing: border-box;
  font-family: "Satoshi", sans-serif !important;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  print-color-adjust: exact;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
  font-size: 11px;
  text-align: center;
}

p {
  margin: 0;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

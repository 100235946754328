#line-chart-tooltip {
  transition: 150ms;
}

.line-chart-tooltip-container {
  width: 130px;
  height: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-chart-tooltip-content {
  width: 130px;
  height: 70px;
  background-color: white;
  filter: drop-shadow(0px 6.303px 12.606px rgba(50, 50, 71, 0.06))
    drop-shadow(0px 6.303px 6.303px rgba(50, 50, 71, 0.08));
  border-radius: 12px;
  padding-top: 8px;
  padding-inline: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.line-chart-tooltip-arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.line-chart-tooltip-title {
  font-family: "Satoshi";
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: rgba(60, 60, 67, 0.6);
}

.line-chart-tooltip-text {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-top: 4px;
}

.line-chart-tooltip-text-ellipsis {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.line-chart-tooltip-bottom-line {
  font-size: 10px;
  line-height: 16px;
}
